
import { transKey, getStoreItem } from "./store";
export const transMsgs = {
    Arabic: {
        reply2: "",
        reply3: "",
        reply4: "",
        reply5: ""
    },
    Portuguese: {},
    Chinese: {},
    Vietnamese: {
        endbot: "Dịch vụ robot đã bị ngắt kết nối, tôi đã liên hệ với bộ phận chăm sóc khách , vui lòng kiên nhẫn chờ đợi",
        endchat: "Dịch vụ khách hàng đã kết thúc.",
        ifpicmsg: "Xin lỗi, hiện tại bot chưa có khả năng xử lý ảnh. Vui lòng nhắn “Gọi admin”, bot sẽ giúp bạn liên hệ nhân viên CSKH(bot)",
        comeinmsg: "Xin chào, tôi là thư ký robot của bộ phận chăm sóc khách hàng. Tôi đã sẵn sàng để trả lời tất cả câu hỏi của bạn. Vui lòng miêu tả ngắn gọn chính xác nhất vấn đề bạn gặp phải, và VIẾT CÓ DẤU để được hỗ trợ tốt nhất(bot)",
        comeinmsg66:'Xin chào, tôi là Robot bộ phận CSKH  \n- Vấn đề về NẠP TIỀN, vui lòng nhắn "3"  \n- Vấn đề về RÚT TIỀN, vui lòng nhắn "4"  \n- Các vấn đề khác, vui lòng nhắn "5" \n- Link tải game, vui lòng nhắn "6"',
        comeinmsg369:'Xin chào, tôi là Robot bộ phận CSKH \n- Để nhận code tân thủ, vui lòng nhắn "1" \n- Không thể sử dụng code, vui lòng nhắn "2" \n- Vấn đề về NẠP TIỀN, vui lòng nhắn "3"  \n- Vấn đề về RÚT TIỀN, vui lòng nhắn "4"  \n- Các vấn đề khác, vui lòng nhắn "5" \n- Link tải game, vui lòng nhắn "6"',
        // reply2:"Xin chào, nếu bạn đổi code không thành công và hệ thống đồng thời ra cảnh báo “Không thể đổi thêm CDK này”, điều đó đồng nghĩa bạn đã từng nhận code tân thủ và không thể đổi thêm nữa. Mỗi người chơi chỉ có thể nhận 1 code duy nhất, khác tài khoản vẫn không được. Xin lưu ý!!! \n Nếu bạn không gặp cảnh báo trên, vui lòng liên hệ bộ phận chăm sóc khách hàng để được hỗ trợ",
        reply2:"Xin chào, người chơi cần phải LIÊN KẾT TÀI KHOẢN NGÂN HÀNG thì mới có thể đổi code. \nNếu bạn đã liên kết nhưng vẫn đổi code không thành công và hệ thống đồng thời ra cảnh báo 'Không thể đổi thêm CDK này', điều đó đồng nghĩa bạn đã từng nhận code tân thủ và không thể đổi thêm nữa. Mỗi người chơi chỉ có thể nhận 1 code duy nhất, khác tài khoản vẫn không được. Xin lưu ý！！！\nNếu bạn không gặp cảnh báo trên, vui lòng liên hệ bộ phận chăm sóc khách hàng để được hỗ trợ.",
        reply3:"Vui lòng cung cấp ID của bạn và ảnh bill chuyển khoản (bao gồm số tiền, thời gian, nội dung chuyển khoản và tài khoản người nhận)",
        reply4:"Vui lòng cung cấp ID của bạn và ảnh giao dịch rút trong game",
        reply5:" Xin chào, vui lòng nêu rõ vấn đề mà bạn gặp phải để chúng tôi có thể hỗ trợ tốt nhất",
        activityEnd:"Sự kiện code tân thủ đã kết thúc.",
        activityTip:"Vui lòng nhắn “ID + CDKEY” vào khung chat này,  hệ thống sẽ tự \nđộng tặng code cho bạn.\nVí dụ: 123456 CDKEY",
        activityEnd_369:"Xin chào, vui lòng sử dụng số điện thoại di động của bạn làm tên đăng nhập để đăng ký tài khoản trò chơi. Nếu đáp ứng đủ yêu cầu, bạn sẽ tự động nhận được 38888 sau khi hoàn tất đăng ký. Nếu bạn không đủ điều kiện, bạn sẽ không nhận được phần thưởng sau khi đăng ký. Ngoài ra, nền tảng chúng tôi còn có rất nhiều các hoạt động phong phú khác, vui lòng theo dõi và đón chờ.Chúc bạn chơi game vui vẻ, xin cảm ơn!"
     }
};

export const getMsgByTrans = (key) => {
    try {
        const trans = getStoreItem(transKey) || "Vietnamese";

        return transMsgs[trans][key];

    } catch (error) {
        return transMsgs["Vietnamese"][key];
        return "";
    }
}
export const filterStrs=[transMsgs["Vietnamese"]["comeinmsg369"],transMsgs["Vietnamese"]["ifpicmsg"],transMsgs["Vietnamese"]["reply2"],transMsgs["Vietnamese"]["reply3"],transMsgs["Vietnamese"]["reply4"],transMsgs["Vietnamese"]["reply5"],"Xin chào, tôi cần bạn cung cấp ID để có thể hỗ trợ tốt hơn. ID bao gồm 6-7 chữ số, được hiển thị sau khi nhấn vào ảnh đại diện của bạn(bot)","Xin chào","Get started"]
