import { useState, useEffect, useCallback, useRef } from "react";
import { ChatSDK, getChat, getChatsList } from "../utils/chat-sdk";
import mynotify from "../assets/sound/notify.mp3";
import mymessage from "../assets/sound/message.mp3";
import incoming_chat from "../assets/sound/incoming_chat.mp3";
import needadmin from "../assets/sound/needadmin.mp3";

import { clearStore, setBotStore, set36Store, get36Store, getBotStore, notifyKey, transKey, transStatusKey, getStoreItem, getMsgNumStore, setMsgNumStore, getBotMsgNumStore, setBotMsgNumStore, setTransStore, getTransStore } from "../utils/store";
import { getMsgByTrans, filterStrs } from "../utils/constants";

const getLoginName = () => {
  return ChatSDK._agentDetails.my_profile?.name;
}
const getLastMessages = async (chatInfo) => {
  try {
    const chatId = chatInfo?.id;
    const chatLastThreadId =
      chatInfo?.thread?.id ||
      chatInfo?.last_thread_summary?.id;

    const { thread, id } = await getChat(chatId, chatLastThreadId)

    if (id === chatId && thread) {
      return thread.events;
    } else {
      return []
    }
  } catch (error) {
    console.error(`Unable to fetch last messages from chat - ${chatInfo?.id}`)
    console.error(error)
  }
}

const isEmoji = (text) => {
  if (!text) {
    return false;
  }
  let emojiReg = /([0-9|*|#]\uFE0F\u20E3)|([0-9|#]\u20E3)|([\u203C-\u3299]\uFE0F\u200D)|([\u203C-\u3299]\uFE0F)|([\u2122-\u2B55])|(\u303D)|([A9|AE]\u3030)|(\uA9)|(\uAE)|(\u3030)|([\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF])|([\uDC00-\uDFFF])|([\uD83C|\uD83D|\uD83E])|([\u200D])|[\uFE0F]/

  return emojiReg.test(text);
}

const isCdKey = (str) => {
  // 将字符串转换为小写
  if(!str){
    return false;
  }
  const lowerStr = str.toLowerCase();

  // 检查前五个字符是否为 'cdkey'
  const startsWithCdKey = lowerStr.startsWith('cdkey');

  // 检查后五个字符是否为 'cdkey'
  const endsWithCdKey = lowerStr.endsWith('cdkey');

  // 返回是否前五个或后五个字符匹配
  return startsWithCdKey || endsWithCdKey;
};

const isCodeFun=(str)=>{
  if(!str){
    return false;
  }
  const text = str.toLowerCase();
  return text === "code" || text.includes("xin code") || text.includes("38888") || text.includes("38k")
}

const platid = process.env.REACT_APP_PLAT_ID;


const addPv = (type) => {
  try {
    const requestOptions2 = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
      body: JSON.stringify({ platid: platid, type: type })
    };
    fetch("/addPv", requestOptions2)
      .then(res => res.json())
      .then(data => {

      })
  } catch (error) {
    console.error("addPv error", error)
  }

}


const botjsob = [
  { cid: "52b8051e4af26aa799d8c3a1ab31f1c3", bot: "/vn_66_faqbot", msbot: "/vn_66_msbot", url: "https://www.b66bak.club" },
  { cid: "814552ce53c68742c3989f3b61bd760d", bot: "/vn_66_faqbot", msbot: "/vn_66_msbot", url: "https://www.b66bak.club" },
  { cid: "7dd9b143440e4a4f0276e2ea8533d356", bot: "/vn_369_faqbot", msbot: "/vn_369_msbot", url: "https://www.b369bak.com" },
  { cid: "454eab6624be0df1158b4eb3122c4dca", bot: "/vn_g666_faqbot", msbot: "/vn_g666_msbot", url: "https://www.g666.win" }
]

const is66 = process.env.REACT_APP_CLIENT_ID != '454eab6624be0df1158b4eb3122c4dca'
const is369 = process.env.REACT_APP_CLIENT_ID == '7dd9b143440e4a4f0276e2ea8533d356'

const isneedadmin = (text) => {
  if (!text || text === '') {
    return false
  }
  if (text.toLowerCase().indexOf("goi admin") >= 0 || text.toLowerCase().indexOf("gọi admin") >= 0 || text.toLowerCase().indexOf("transferir para atendimento humano") >= 0) {
    return true
  } else {
    return false;
  }

}



/**
 * Handle chat messages related to provided chatId
 * @param {*} chatId 
 */
export function useChatMessages() {
  const [openTrans, setOpenTrans] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [accessToken] = useState(null);
  const lastReplyByBot = useRef('');
  const [sysTags, setSysTags] = useState(null);

  const getThreadId = (chat) => {
    if (chat?.last_thread_summary) {
      return `${chat.last_thread_summary.id}`
    }

    return `${chat?.thread.id}`
  }

  const needAdmin = (chatid) => {
    setChatList((prevChatList) => {
      if (!prevChatList) {
        return;
      }
      const chatToChange = prevChatList.find(r => r.id === chatid);
      if (chatToChange) {
        setBotStore(chatid, "1")
        chatToChange.needAdmin = true;
        chatToChange.noBot = true;
      }
      return [...prevChatList];
    })
  }

  const sendMessage = (chatId, value) => {
    if (chatId && value) {
      ChatSDK.sendMessage(chatId, value).then(data => {

      }).catch(error => {
        console.log("sendmessage error------", error)
        // needAdmin(chatId)
        // setTimeout(() => {
        //   ChatSDK.sendMessage(chatId, value)
        // }, 1000);
      });

    }

  }
  let incoming = new Audio(incoming_chat);
  let message = new Audio(mymessage);
  let notify = new Audio(mynotify);
  let needadm = new Audio(needadmin);


  const playSound = (type) => {

    const n = getStoreItem(notifyKey);
    if (n && n == "1") {
      switch (type) {
        case 1:
          incoming.play();

          break;
        case 2:
          message.play();

          break;
        case 3:
          needadm.play();

          break;
        default:
          message.play();

          break;
      }
    }

  }

  const clearMessages = () => setMessages([])

  const isPic = (event, chatId) => {


    if ((event?.url || isEmoji(event?.text)) && chatId) {
      const ifpicmsg = getMsgByTrans("ifpicmsg");
      if (ifpicmsg) {
        setTimeout(() => {
          sendMessage(chatId, ifpicmsg);
        }, 1000);

      }

    }

  }

  const fetchMessages = useCallback(async (chatInfo) => {
    clearMessages()
    setActiveChat(chatInfo)

    const events = await getLastMessages(chatInfo)
    const transList = getTransStore(chatInfo?.id);
    const lens = events?.length
    events?.forEach?.((e, index) => {

      const transStatus = getStoreItem(transStatusKey);
      if (e.text && !isEmoji(e.text) && transStatus && transStatus == "1") { //&& index >= events.length - 3

        if (transList) {
          const item = transList?.find(r => r.id === e.id);
          if (item) {

            e.chineseText = item.v;
          }
        }
        if ((!e.chineseText || e.chineseText == "") && index >= events.length - 3 && isNaN(e.text) && filterStrs.indexOf(e.text) < 0 && !isCdKey(e.text)) {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
            body: JSON.stringify({ text: e.text })
          };
          fetch('/Translate_to_Chinese', requestOptions)
            .then(res => res.json())
            .then(data => {
              /*接到request data後要做的事情*/
              //this.setState({repoName: data[0]['name']});
              setTransStore(chatInfo?.id, e.id, data.translated_text)
              setMessages((prevMessages) => {
                if (!prevMessages) {
                  return;
                }
                const item = prevMessages.find(r => r.id === e.id);
                if (item) {
                  item.chineseText = data.translated_text;
                }
                return [...prevMessages];
              })


            })
        }

      } else { }
    })
    setMessages(events);
  }, [setActiveChat])

  /**
   * Handle events related to chat list
   */
  useEffect(() => {

    const close_chat = (chatid) => {
      try {

        fetch('https://api.livechatinc.com/v3.5/agent/action/deactivate_chat', {
          method: 'POST',
          body: JSON.stringify({
            "id": chatid
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": ChatSDK._getToken()
          }
        }).then(res => res.json())
          .then(data => {
            // const newChats = [...(chatList || [])];

            // setMsgNumStore(chatid, 0)
            // setBotMsgNumStore(chatid, 0)

            // if (activeChat?.id == chatid) {
            //   const chatToChange = chatList?.filter(item => {
            //     return item.id != chatid
            //   });

            //   if (chatToChange) {

            //     fetchMessages(chatToChange[0])
            //   } else {
            //     fetchMessages(null)
            //   }
            // }
            // clearStore(chatid)


            //window.location.reload();
          })
          .catch(error => console.log(error));
        return true;
      } catch (error) {
        console.log("close chat error---", error)
      }
    }

    const initiate_chat = (text, chatid, psid, fname, lname) => {
      const requestOptions2 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
        body: JSON.stringify({ psid: psid, last_name: lname || "", first_name: fname || "", chatid: chatid })
      };
      fetch("/initiate_chat", requestOptions2)
        .then(res => res.json())
        .then(data => {
          if (data) {


          }

        })
    }

    const sendMsg = (text, chatid) => {
      if (!text || !chatid || isEmoji(text)) {
        return;
      }
      const botitem = botjsob.find(item => item.cid === process.env.REACT_APP_CLIENT_ID);
      if (botitem) {
        const requestOptions2 = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
          body: JSON.stringify({ message: text, chatid: chatid })
        };
        fetch(botitem.msbot, requestOptions2)
          .then(res => res.json())
          .then(data => {

            lastReplyByBot.current = data?.reply;
            let reply = data?.reply;
            if (reply?.[0] === '"') {
              reply = reply.substr(1);
            }
            if (reply?.length && reply?.[reply?.length - 1] === '"') {
              reply = reply.substr(0, reply.length - 1);
            }
            // sendMessage(chatid, reply + "(bot)");

            ChatSDK.sendMessage(chatid, reply + "(bot)").then(data => {
              if (data && data.event_id) {
                setTimeout(function () {

                  close_chat(chatid)

                }, 3000);
              }
            }).catch(error => {

              console.error("sendMessage001 发送错误", error)
              setTimeout(() => {
                ChatSDK.sendMessage(chatid, reply + "(bot)").then(data2 => {
                  if (data2 && data2.event_id) {
                    setTimeout(function () {
                      close_chat(chatid)
                    }, 3000);
                  }
                }).catch(error2 => {

                  // needAdmin(chatid)
                  console.error("sendMessage002 发送错误", error2)

                })
              }, 1000);
            })
          }).catch(error => {

          })
      }


    }


    const getTags = (incomingChat) => {

      try {
        const requestOptions2 = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
          body: JSON.stringify({ psids: [incomingChat?.psid], platid: platid })
        };
        fetch("/vn_369_gettags", requestOptions2)
          .then(res => res.json())
          .then(data => {
            if (data) {
              if (data && data.result && data.result.length > 0) {
                setChatList((prevChatList) => {
                  if (!prevChatList) {
                    return;
                  }
                  const item = prevChatList.find(r => r.id === incomingChat?.id);
                  if (item) {
                    item.tags = JSON.parse(data.result[0]["usertags"]);
                  }
                  return [...prevChatList];
                })

              }
            }

          })
      } catch (error) {

      }

    }

    const handleIncomingChats = ({ payload }) => {
      const incomingChat = payload?.chat;

      const loginName = getLoginName();
      try {
        if (incomingChat?.users.find(({ type, present, name }) => type === 'agent' && present == true && name === loginName)) {
          const client_id = incomingChat?.properties.source?.client_id;
          const text = incomingChat?.thread?.events[0]?.text || '';
          const conversation_link = client_id ? incomingChat?.properties[client_id]?.conversation_link : null;
          const isfb = conversation_link ? (conversation_link.indexOf("facebook.com") >= 0 ? true : false) : false;
          const iskey = isCdKey(text);
          const isCode= isCodeFun(text);
          if (isfb) {
            const psid = client_id ? incomingChat?.properties[client_id]?.id : null;
            const fname = client_id ? incomingChat?.properties[client_id]?.first_name : null;
            const lname = client_id ? incomingChat?.properties[client_id]?.last_name : null;
            initiate_chat(text, incomingChat?.id, psid, fname, lname)
            incomingChat.psid = psid;
            isPic(incomingChat?.thread?.events[0], incomingChat?.id)
          }
          else {
            const customer = incomingChat?.users.find(({ type }) => type === 'customer')
            incomingChat.psid = customer?.id;
          }

          if (iskey) {
            set36Store(incomingChat.id, "10")
            incomingChat.needInit = false;
          } else {
            set36Store(incomingChat.id, "0")
            incomingChat.needInit = true;
          }
          if (!chatList.some(({ id }) => id === incomingChat.id)) {
            incomingChat.lastDate = incomingChat?.thread?.created_at || ''
            incomingChat.lastText = text || '';
            // if (!chatList.length) {
            //   // setActiveChat(incomingChat);

            // }
            setChatList(prevData => {
              if (!prevData) {
                fetchMessages(incomingChat);
                return [incomingChat];
              } else {
                return [...prevData, incomingChat];
              }
            });
            setChatList(prevData => [...prevData?.sort((a, b) => new Date(b.lastDate) - new Date(a.lastDate))])
          }


          const comeinmsg = getMsgByTrans(is66 ? "comeinmsg66" : "comeinmsg369");

          if (comeinmsg && !iskey && !isCode) {
            setTimeout(() => {
              sendMessage(incomingChat?.id, comeinmsg);
            }, 1000);

          }
          if (iskey) {
            setTimeout(() => {
              sendMsg(text, incomingChat?.id)
            }, 1000);
            addPv(6)

          }
          if (isCode) {
            addPv(1)
            setTimeout(() => {
              sendMessage(incomingChat?.id, is369? getMsgByTrans("activityEnd_369"):getMsgByTrans("activityTip"));
            }, 1000);
            setTimeout(function () {
  
              close_chat(incomingChat?.id)
  
            }, 4000);
          }
          setTimeout(() => {
            getTags(incomingChat)
          }, 3000);

          playSound(1);
        } else {
          console.error('新消息进来失败了-----', payload)
        }
      } catch (error) {
        console.error('新消息进来失败了222-----', error)
      }

    };

    const handleClosingThread = ({ payload }) => {

      const closedChat = payload?.chat_id;
      const updatedChatList = chatList.filter(({ id }) => id !== closedChat);

      setChatList((prevChatList) => {
        if (!prevChatList) {
          return;
        }
        return prevChatList.filter(item => item.id !== closedChat)
      })
      if (activeChat?.id == closedChat) {
        if (updatedChatList) {
          // setActiveChat(updatedChatList[0])
          fetchMessages(updatedChatList[0]);
        } else {
          setActiveChat(null)
        }
      }
      clearStore(closedChat);


    };

    const handleRemoveThread = ({ payload }) => {

      const closedChat = payload?.chat_id;

      setChatList((prevChatList) => {
        if (!prevChatList) {
          return;
        }
        const updatedChatList = prevChatList.filter(({ id }) => id !== closedChat);

        if (activeChat?.id == closedChat) {
          if (updatedChatList) {
            // setActiveChat(updatedChatList[0])
            fetchMessages(updatedChatList[0]);
          } else {
            setActiveChat(null)
          }
        }

        return [...updatedChatList];
      })
      clearStore(closedChat);

    };

    // Listen to incoming chats
    ChatSDK.on("incoming_chat", handleIncomingChats);
    // Listen to closing chats
    ChatSDK.on("chat_deactivated", handleClosingThread);

    ChatSDK.on("user_removed_from_chat", handleRemoveThread);

    return () => {
      ChatSDK.off("incoming_chat", handleIncomingChats);
      ChatSDK.off("chat_deactivated", handleClosingThread);
      ChatSDK.off("user_removed_from_chat", handleRemoveThread);
    };
  }, [chatList, fetchMessages]);

  /**
   * Handle messaging events
   */
  useEffect(() => {
    const handleThreads = ({ payload }) => {
      if (
        payload?.chat?.id === activeChat?.id &&
        (payload?.chat?.threads?.length ||
          payload?.chat?.thread)
      ) {
        const msgs = "";
        try {
          if (payload && payload.chat && payload.chat.threads) {
            msgs = payload?.chat?.threads[0]?.events;
          } else {
            msgs = payload?.chat?.thread?.events;
          }
        } catch (error) {

        }

        setMessages(msgs);
      }
    };


    const getBotName = (fullname, isfb) => {
      if (isfb) {
        const botitem = botjsob.find(item => item.cid === process.env.REACT_APP_CLIENT_ID);
        if (botitem) {
          return botitem.msbot;
        }
        return "/";
      } else {
        const botitem2 = botjsob.find(item => item.cid === process.env.REACT_APP_CLIENT_ID);
        if (botitem2) {
          return botitem2.bot;
        }
        return "/";
      }


      // if (fullname == "Vietnamese") {

      //   return "/"
      // };
      // if (fullname == "Portuguese") return "/brbot";
      // if (fullname == "Chinese") return "/cnbot";
      // if (fullname == "Arabic") return "/arbot";
      //if (fullname == "Vietnamese") return "/vnbot";
      // return "/vnbot";
    }

    const needAdmin = (chatid) => {
      setChatList((prevChatList) => {
        if (!prevChatList) {
          return;
        }
        const item = prevChatList.find(r => r.id === chatid);
        if (item) {
          setBotStore(chatid, "1")
          item.needAdmin = true;
          item.noBot = true;
        }
        return [...prevChatList];
      })

    }

    const setChat36 = (chatid, val) => {

      setChatList((prevChatList) => {
        if (!prevChatList) {
          return;
        }
        const chatToChange = prevChatList.find(r => r.id === chatid);
        if (chatToChange) {
          set36Store(chatid, val + "")
          if (val >= 5) {
            chatToChange.needInit = false;
            if (val >= 30) {
              chatToChange.pin = true;
            }
            chatToChange.userType = val;
          }
        }
        return [...prevChatList];
      })
    }

    const setMsgNum = (chatid, created_at, text) => {
      setChatList((prevChatList) => {
        if (!prevChatList) {
          return;
        }
        const chatToChange = prevChatList.find(r => r.id === chatid);
        if (chatToChange) {
          const lastNum = getMsgNumStore(chatid)
          const num = lastNum ? parseInt(lastNum) + 1 : 1;
          setMsgNumStore(chatid, num)
          chatToChange.msgNum = num;
          if (platid == 1) {
            chatToChange.lastDate = created_at
          }

          chatToChange.lastText = text
        }
        return [...prevChatList];
      })
      // setChatList(newChats);
    }
    const setBotMsgNum = (chatid) => {
      setChatList((prevChatList) => {
        if (!prevChatList) {
          return;
        }
        const chatToChange = prevChatList.find(r => r.id === chatid);
        if (chatToChange) {
          const lastNum = getBotMsgNumStore(chatid)
          const num = lastNum ? parseInt(lastNum) + 1 : 1;
          setBotMsgNumStore(chatid, num)
          chatToChange.botMsgNum = num;
        }
        return [...prevChatList];
      })
    }

    const close_chat = (chatid) => {
      try {

        fetch('https://api.livechatinc.com/v3.5/agent/action/deactivate_chat', {
          method: 'POST',
          body: JSON.stringify({
            "id": chatid
          }),
          headers: {
            "Content-Type": "application/json",
            "Authorization": ChatSDK._getToken()
          }
        }).then(res => res.json())
          .then(data => {
            // const newChats = [...(chatList || [])];

            // setMsgNumStore(chatid, 0)
            // setBotMsgNumStore(chatid, 0)

            // if (activeChat?.id == chatid) {
            //   const chatToChange = chatList?.filter(item => {
            //     return item.id != chatid
            //   });

            //   if (chatToChange) {

            //     fetchMessages(chatToChange[0])
            //   } else {
            //     fetchMessages(null)
            //   }
            // }
            // clearStore(chatid)
            //window.location.reload();
          })
          .catch(error => console.log(error));
        return true;
      } catch (error) {
        console.log("close chat error---", error)
      }
    }

    const botSend = (reply, chat_id, isfb, isCDK) => {
      if (reply?.[0] === '"') {
        reply = reply.substr(1);
      }
      if (reply?.length && reply?.[reply?.length - 1] === '"') {
        reply = reply.substr(0, reply.length - 1);
      }
      // sendMessage(payload.chat_id, reply + "(bot)");

      ChatSDK.sendMessage(chat_id, reply + "(bot)").then(data => {
        if (isfb && isCDK && data && data.event_id) {
          setTimeout(function () {

            close_chat(chat_id)

          }, 3000);
        }
      }).catch(error => {
        console.error("sendMessage01 发送错误", error)
        setTimeout(() => {
          ChatSDK.sendMessage(chat_id, reply + "(bot)").then(data2 => {
            if (isfb && isCDK && data2 && data2.event_id) {
              setTimeout(function () {
                close_chat(chat_id)
              }, 3000);
            }
          }).catch(error2 => {

            // needAdmin(chat_id)
            console.error("sendMessage02 发送错误", error2)

          })
        }, 1000);
      })
    }

    const fetchbot = (boturl, sendbotmsg, chat_id, isfb, isCDK, errorNum) => {
      if (errorNum >= 3) {
        needAdmin(chat_id)
        return;
      }
      const requestOptions2 = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
        body: JSON.stringify({ message: sendbotmsg, chatid: chat_id })
      };
      fetch(boturl, requestOptions2)
        .then(res => res.json())
        .then(data => {
          try {
            lastReplyByBot.current = data?.reply;
            let reply = data?.reply;
            if (!isCDK) {
              isCDK = data?.closechat
            }
            botSend(reply, chat_id, isfb, isCDK)
          } catch (error) {
            // needAdmin(chat_id)
            console.error("bot 请求成功 内部错误", error)
          }

        }).catch(error => {
          console.error("bot 请求发送错误", error)
          fetchbot(boturl, sendbotmsg, chat_id, isfb, isCDK, errorNum + 1)
        })
    }

    const handleNewMessages = ({ payload }) => {

      const eventFromUserId = payload?.event?.author_id;
      const currentChat = chatList?.filter(item => item.id === payload?.chat_id)?.[0];
      if (!currentChat) {

        console.error(' handleNewMessages 获取失败 payload---', payload)
        console.error('  获取失败 chatList---', chatList)
        // window.location.reload()
        return;
      }
      const users = currentChat?.users;
      const userForEvent = users?.find(item => item.id === eventFromUserId);

      const isadmin = isneedadmin(payload?.event?.text);
      const isemo = isEmoji(payload?.event?.text)
      var isCDK = false;
      var isCode = false;
      if (payload?.event?.text) {
        isCDK =isCdKey(payload?.event?.text) 

        if(!isCDK && is66){
          isCode = isCodeFun(payload?.event?.text)
        }
      }

      if (userForEvent?.type === 'customer') {
        try {

          if (isadmin) {
            needAdmin(payload.chat_id)
            setTimeout(() => {
              sendMessage(payload.chat_id, getMsgByTrans("endbot"));
            }, 1000);

            playSound(3);
          }
          else {
            playSound(2);
          }
          if (!currentChat?.noBot) {
            isPic(payload.event, payload.chat_id)
          }

          setMsgNum(payload.chat_id, payload?.event?.created_at, payload?.event?.text)
        } catch (error) {
          console.log("handleNewMessages error ----", error)
        }

      }
      if (userForEvent?.type === 'customer' && payload?.event?.text && !currentChat?.noBot) {
        let isbot = true;
        let sendbotmsg = payload?.event?.text;
        //3，6，9逻辑
        if (isCode) {
          isbot = false;
          setChat36(payload.chat_id, 10)
          addPv(1)
          setTimeout(() => {
            sendMessage(payload.chat_id, is369? getMsgByTrans("activityEnd_369"):getMsgByTrans("activityTip"));
          }, 1000);
          setTimeout(function () {

            close_chat(payload.chat_id)

          }, 4000);
        }
        if (currentChat.needInit) {

          const checkTexts = ["1", "2", "3", "4", "5", "6"];
          const st36 = get36Store(payload.chat_id);

          if (st36 && st36 != "") {
            let st36num = parseInt(st36);
            if (checkTexts.indexOf(payload?.event?.text) < 0) { //不包含3，6，9
              if (isCDK) {
                setChat36(payload.chat_id, 10)
                addPv(1)
              }
              else {
                if (st36num < 2) {
                  isbot = false
                  set36Store(payload.chat_id, (st36num + 1) + "")
                  setTimeout(() => {
                    sendMessage(payload.chat_id, getMsgByTrans(is66 ? "comeinmsg66" : "comeinmsg369"));
                  }, 1000);

                } else {
                  setChat36(payload.chat_id, 5)
                }
              }


            } else {
              let initNum = parseInt(payload?.event?.text);
              setChat36(payload.chat_id, initNum * 10)
              switch (initNum) {
                case 1:
                  if (is66) {
                    setTimeout(() => {
                      sendMessage(payload.chat_id, getMsgByTrans("activityEnd"));
                    }, 1000);
                    setTimeout(function () {

                      close_chat(payload.chat_id)

                    }, 4000);
                  } else {
                    sendbotmsg = "Xin Code"
                  }
                  break;
                case 2:
                  isbot = false;
                  setTimeout(() => {
                    sendMessage(payload.chat_id, getMsgByTrans(is66 ? "activityEnd" : "reply2"));
                  }, 1000);

                  if (is66) {
                    setTimeout(function () {

                      close_chat(payload.chat_id)

                    }, 4000);
                  }

                  break;
                case 3:
                  isbot = false;
                  setTimeout(() => {
                    sendMessage(payload.chat_id, getMsgByTrans("reply3"));
                  }, 1000);
                  needAdmin(payload.chat_id);
                  playSound(3);
                  break;
                case 4:
                  isbot = false;
                  setTimeout(() => {
                    sendMessage(payload.chat_id, getMsgByTrans("reply4"));
                  }, 1000);

                  needAdmin(payload.chat_id);
                  playSound(3);
                  break;
                case 5:
                  isbot = false;
                  setTimeout(() => {
                    sendMessage(payload.chat_id, getMsgByTrans("reply5"));
                  }, 1000);

                  break;
                case 6:
                  initNum = 50;
                  isbot = false;
                  const botitem = botjsob.find(item => item.cid === process.env.REACT_APP_CLIENT_ID);
                  if (botitem) {
                    setTimeout(() => {
                      sendMessage(payload.chat_id, botitem.url);
                    }, 1000);
                  }
                  break;

                default:
                  break;
              }
              addPv(initNum)

            }
          }
        }


        const client_id = currentChat?.properties.source?.client_id;
        const conversation_link = client_id ? currentChat?.properties[client_id]?.conversation_link : null;
        const isfb = conversation_link ? (conversation_link.indexOf("facebook.com") >= 0 ? true : false) : false;

        if (!isadmin && !isemo && isbot) {

          const trans = getStoreItem(transKey);
          //if(isfb){
          const boturl = getBotName(trans, isfb)

          fetchbot(boturl, sendbotmsg, payload.chat_id, isfb, isCDK, 0)

          //}
        }
      }

      if (payload.event.text && payload.event.text.endsWith("(bot)") && userForEvent?.type !== 'customer') {
        setBotMsgNum(payload.chat_id)
      }

      if (payload.chat_id === activeChat?.id) {
        let isFromBot = false;
        if (payload.event.text && payload.event.text.endsWith("(bot)") && userForEvent?.type !== 'customer') {
          isFromBot = true;

        }
        if (payload.event.text && !isemo) {

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': '1f2d3e345a6596de7d6b855c3d7f481e9e852c0243d3d3a8e4cc4a4a4b4a4b4c' },
            body: JSON.stringify({ text: payload.event.text })
          };
          const msgs = [...messages, { ...payload.event, isFromBot }];

          if (activeChat?.id === payload.chat_id) {
            setMessages((prevMessage) => {
              if (!prevMessage) {
                return;
              }
              return [...prevMessage, { ...payload.event, isFromBot }];
            });
          }

          const transStatus = getStoreItem(transStatusKey);
          if (transStatus && transStatus == "1" && isNaN(payload.event.text) && filterStrs.indexOf(payload.event.text) < 0 && !isCdKey(payload.event.text)) {
            fetch('/Translate_to_Chinese', requestOptions)
              .then(res => res.json())
              .then(data => {
                if (activeChat?.id === payload.chat_id) {
                  setMessages((prevMessage) => {
                    if (!prevMessage) {
                      return;
                    }
                    let msgsWithChinese = [...prevMessage];
                    let findMessage = msgsWithChinese?.find(item => item.id === payload.event.id);
                    if (findMessage) {
                      findMessage.chineseText = data.translated_text;
                    } else {
                      msgsWithChinese = [...prevMessage, { ...payload.event, chineseText: data.translated_text }];
                    }
                    return msgsWithChinese;
                  });
                }


                setTransStore(payload.chat_id, payload.event?.id, data.translated_text)
              })
          }



        } else {
          const msgs = [...messages, { ...payload.event }];
          setMessages(msgs);
        }


      }
    };

    // Listen to incoming messages
    ChatSDK.on("incoming_event", handleNewMessages);
    // Listen to event sent after fetch chat threads
    ChatSDK.on("incoming_chat", handleThreads);

    return () => {
      ChatSDK.off("incoming_chat", handleThreads);
      ChatSDK.off("incoming_event", handleNewMessages);
    };
  }, [activeChat?.id, chatList, messages]);

  return {
    sendMessage,
    clearMessages,
    setMessages,
    messages,
    fetchMessages,
    chatList,
    setChatList,
    activeChat,
    openTrans,
    setOpenTrans,
    sysTags,
    setSysTags
  };
}

